<script lang="ts">
export default {
  name: 'message-add-icon',
  props: {
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_4115_12963"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4115_12963)">
      <path
        d="M3.5 20V5C3.5 4.45 3.69583 3.97917 4.0875 3.5875C4.47917 3.19583 4.95 3 5.5 3H17.5C18.05 3 18.5208 3.19583 18.9125 3.5875C19.3042 3.97917 19.5 4.45 19.5 5V10.075C19.3333 10.0417 19.1667 10.0208 19 10.0125C18.8333 10.0042 18.6667 10 18.5 10C18.3333 10 18.1667 10.0042 18 10.0125C17.8333 10.0208 17.6667 10.0417 17.5 10.075V5H5.5V15H12.575C12.5417 15.1667 12.5208 15.3333 12.5125 15.5C12.5042 15.6667 12.5 15.8333 12.5 16C12.5 16.1667 12.5042 16.3333 12.5125 16.5C12.5208 16.6667 12.5417 16.8333 12.575 17H6.5L3.5 20ZM7.5 9H15.5V7H7.5V9ZM7.5 13H12.5V11H7.5V13ZM17.5 20V17H14.5V15H17.5V12H19.5V15H22.5V17H19.5V20H17.5Z"
        fill="#1976D2"
      />
    </g>
  </svg>
</template>

<style scoped></style>
