import { defineStore } from 'pinia'
import { Client, type StompSubscription } from '@stomp/stompjs'
import { computed, ref } from 'vue'
import { useInboxStore } from './inbox'
import { useAuthStore } from './auth'
import { useSupervisionStore } from './supervision'
import { useTaskStore } from './tasks'

type StompSubscriptionWithName = StompSubscription & { name: string }

const EXCHANGE_NAME = 'inbox'

export const useRabbitStore = defineStore('rabbit', () => {
  const client = ref<Client | null>(null)
  const operatorExchanges = ref<string[]>([])
  const exchangeSubscriptions: StompSubscriptionWithName[] = []
  const inboxStore = useInboxStore()
  const authStore = useAuthStore()
  const supervisionStore = useSupervisionStore()
  const taskStore = useTaskStore()

  function openRabbitConnection() {
    if (client.value) return

    client.value = new Client({
      brokerURL: import.meta.env.VITE_RABBIT_BROKER_URL,
      reconnectDelay: 1,
      onConnect: () => {
        console.log('%c rabbit client connect! ', 'background: #444; color: #bada55')
        if (client.value?.connected) {
          subscribeToOperatorInbox()

          if (supervisionStore.currentQueue) {
            unsubscribeFromExchange({
              exchangeName: supervisionStore.currentQueue.stats_exchange_name
            })

            subscribeToExchange({
              exchangeName: supervisionStore.currentQueue.stats_exchange_name,
              fn: supervisionStore.refreshTables
            })
          }
        }
      },
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000
    })

    client.value.activate()
  }

  function subscribeToOperatorInbox() {
    if (!client.value?.connected) throw new Error('Cant subscribe to inbox')
    client.value.subscribe(`/exchange/${EXCHANGE_NAME}`, (message) => {
      const parsedMessage = JSON.parse(message.body)
      console.log(JSON.stringify(parsedMessage, null, 2))

      if (taskStore.tasks?.length > 0) {
        taskStore.tasks.forEach((task) => {
          if (task.request_id === parsedMessage.request_id) {
            const chatSessions = task.chat_sessions
            if (chatSessions && chatSessions.length > 0) {
              const chatIndex = chatSessions.findIndex((chat) => chat.uri === parsedMessage.uri)
              if (chatIndex >= 0) {
                chatSessions[chatIndex] = parsedMessage
              }
            }
          }
        })
      }

      const inboxIndex = inboxStore.inbox.findIndex((item) => item.id === parsedMessage.id)

      if (inboxIndex >= 0) {
        if (
          parsedMessage.assigned_user === null ||
          parsedMessage.assigned_user !== authStore.userChatId
        ) {
          inboxStore.inbox.splice(inboxIndex, 1)
        } else {
          inboxStore.inbox[inboxIndex] = parsedMessage
        }
      } else {
        if (parsedMessage.assigned_user === authStore.userChatId) {
          inboxStore.inbox.push(parsedMessage)
        }
      }
    })
  }

  function subscribeToExchange({
    exchangeName,
    fn
  }: {
    exchangeName: string
    fn?: (params: any) => void
  }) {
    if (!client.value?.connected) throw new Error('Brocker disconnect cant subscribe to exchange')
    if (!exchangeName) return
    const subscription = client.value.subscribe(`/exchange/${exchangeName}`, (message) => {
      const parsedMessage = JSON.parse(message.body)
      if ('campaigns' in parsedMessage) {
        if (fn) fn(parsedMessage)
      }
    })
    if (exchangeSubscriptions.some((exchange) => exchange.name === exchangeName)) return
    console.log('%c subscribe to exchange: ', 'background: #008F00; color: #FFF', exchangeName)
    exchangeSubscriptions.push({ ...subscription, name: exchangeName })
  }

  function unsubscribeFromExchange({ exchangeName }: { exchangeName: string }) {
    if (!client.value?.connected)
      throw new Error('Brocker disconnect cant unsubscribe from exchang')

    if (!exchangeName) return

    const subscriptionIndex = exchangeSubscriptions.findIndex((exchange) => {
      return exchange.name === exchangeName
    })

    if (subscriptionIndex >= 0) {
      exchangeSubscriptions[subscriptionIndex].unsubscribe()
      exchangeSubscriptions.splice(subscriptionIndex, 1)
      console.log('%c unsubscribe to exchange: ', 'background: #FF0000; color: #FFF', exchangeName)
    }
  }

  return {
    client: computed(() => client.value),
    operatorExchanges,
    openRabbitConnection,
    subscribeToOperatorInbox,
    subscribeToExchange,
    unsubscribeFromExchange
  }
})
