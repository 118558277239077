export function convertMinutesToMs(minutes: number) {
  return minutes * 60 * 1000
}

export function convertDateToMs(date: Date | string) {
  return new Date(date).getTime()
}
export function formatDateTime(date: string) {
  if (!date) return '--/--/--'

  const newDate = new Date(date)

  const formattedDate = new Intl.DateTimeFormat('es-AR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  }).format(newDate)

  const formattedTime = new Intl.DateTimeFormat('es-AR', {
    hour: '2-digit',
    minute: '2-digit'
  }).format(newDate)

  return `${formattedDate} | ${formattedTime}`
}

export function formatDate(date: string) {
  if (!date) return '--/--/--'

  const newDate = new Date(date)

  const formattedDate = new Intl.DateTimeFormat('es-AR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  }).format(newDate)

  return formattedDate
}

export function formatCurrency(amount: number) {
  if (!amount) return '$0'

  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS'
  })

  return formatter.format(amount)
}

export function formatList(list: string[], conjunction: string = 'y') {
  if (list.length === 0) {
    return ''
  } else if (list.length === 1) {
    return list[0]
  } else if (list.length === 2) {
    return list.join(' ' + conjunction + ' ')
  } else {
    const ultimoNombre = list.pop()
    return list.join(', ') + ', ' + conjunction + ' ' + ultimoNombre
  }
}

export function getAvatar(typeRoom: number) {
  const TYPE_ROOMS: Record<number, any> = {
    1: {
      bgColor: 'amber-lighten-4',
      icon: 'mdi-account',
      iconColor: 'orange darken-1'
    },
    2: {
      bgColor: 'deep-purple-lighten-5',
      icon: 'mdi-office-building-outline',
      iconColor: 'deep-purple'
    },
    3: {
      bgColor: 'blue-lighten-5',
      icon: 'mdi-account-hard-hat-outline',
      iconColor: 'blue darken-2'
    },
    5: {
      bgColor: 'red-lighten-4',
      icon: 'mdi-account-off-outline',
      iconColor: 'red'
    }
  }

  return TYPE_ROOMS[typeRoom] || { bgColor: '', icon: '', iconColor: '' }
}

export function inboxHasWhatsapp(typeRoom: number) {
  if (!typeRoom) return false
  const CHAT_WITH_WHATSAPP = [1, 3, 5]
  return CHAT_WITH_WHATSAPP.includes(typeRoom)
}

export const getRequestStatusIcon = (status: string): { icon: string; color: string } => {
  const stateMappings: Record<string, { icon: string; color: string }> = {
    'En Curso': {
      icon: 'mdi-rotate-right',
      color: 'amber-darken-4'
    },
    Finalizado: {
      icon: 'mdi-check-circle',
      color: 'green-darken-2'
    },
    Cancelado: {
      icon: 'mdi-close-circle',
      color: 'red'
    }
  }

  return stateMappings[status] || { icon: '', color: '' }
}

export const getTaskStatusChip = (status: string): { color: string; text: string } => {
  const statusMapping: Record<string, { color: string; text: string }> = {
    Nuevo: { color: 'blue-darken-2', text: 'Nueva' },
    Resuelto: { color: 'green', text: 'Realizada' },
    Delete: { color: 'red', text: 'Eliminada' },
    Reprogramada: { color: 'orange-darken-2', text: 'Reprogramada' }
  }

  return statusMapping[status] || { color: 'grey', text: '' }
}

export const getBudgetStatusChip = (status: string): { color: string; text: string } => {
  const statusMapping: Record<string, { color: string; text: string }> = {
    Nuevo: { color: 'blue-darken-2', text: 'Nuevo' },
    Aprobado: { color: 'green', text: 'Aprobado' },
    Desaprobado: { color: 'red', text: 'Desaprobado' },
    Vencido: { color: 'red', text: 'Vencido' },
    'En revisión': { color: 'orange-darken-2', text: 'En revisión' },
    'Pendiente de aprobación': { color: 'purple-darken-2', text: 'Pend. de aprobación' }
  }

  return statusMapping[status] || { color: 'grey', text: '' }
}

export const getVisitStatusChip = (status: string): { color: string; text: string } => {
  const statusMapping: Record<string, { color: string; text: string }> = {
    Realizada: { color: 'green', text: 'Realizada' },
    Anulada: { color: 'red', text: 'Anulada' },
    Cancelada: { color: 'red', text: 'Cancelada' },
    'Cancelada Prestador': { color: 'red', text: 'Cancelada Prestador' },
    'Cancelada Cliente': { color: 'red', text: 'Cancelada Cliente' },
    'No se pudo dar solución': { color: 'red', text: 'No se pudo dar solución' },
    'Cliente no quiso abonar': { color: 'red', text: 'Cliente no quiso abonar' },
    'Cliente Ausente': { color: 'red', text: 'Cliente Ausente' },
    Pendiente: { color: 'orange-darken-2', text: 'Pendiente' },
    'En domicilio': { color: 'orange-darken-2', text: 'En domicilio' },
    'En curso': { color: 'orange-darken-2', text: 'En curso' },
    'Reprogramada Cliente': { color: 'orange-darken-2', text: 'Repro. Cliente' },
    'Reprogramada Prestador': { color: 'orange-darken-2', text: 'Repro. Prestador' }
  }

  return statusMapping[status] || { color: 'grey', text: '' }
}

export const getOrganizationChipColor = (organization: string): string => {
  const organizationMappings: Record<string, string> = {
    Qualia: 'teal-lighten-1',
    Galicia: 'deep-orange-darken-1'
  }

  return organizationMappings[organization] || 'grey'
}
