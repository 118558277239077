<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useRabbitStore } from '../stores/rabbit'
import { onMounted } from 'vue'
import { useInboxStore } from '../stores/inbox'
import { socket } from '@/socket'
import { useConnectionStore } from '../stores/socket-connection'
import { useAuthStore } from '../stores/auth'

import QueryStatsIcon from '@/components/iconos/query-stats-icon.vue'
import StatisticModal from '@/components/StatisticModal.vue'

import { useTaskConnectionStore } from '@/stores/socket-task-connection'
import { useTaskStore } from '@/stores/tasks'

socket.off()

const router = useRouter()
const route = useRoute()

const drawer = ref(true)
const rail = ref(true)

const inboxStore = useInboxStore()
const rabbitStore = useRabbitStore()
const authStore = useAuthStore()
const connectionStore = useConnectionStore()
const taskSocketStore = useTaskConnectionStore()
const taskStore = useTaskStore()

const sidebarItems = computed(() => [
  {
    name: 'Tareas',
    to: 'TasksView',
    icon: 'mdi-check-circle-outline',
    content: taskStore.tasksToDo
  },
  {
    name: 'Chats',
    to: 'ChatsView',
    icon: 'mdi-message-text-outline',
    content: inboxStore.unreadInboxs
  }
])

const hasSupervisionAccess = computed(
  () => authStore.user?.is_team_leader || authStore.user?.is_referente
)

const supervisionItems = [
  {
    name: 'Tareas',
    to: 'SupervisionTaskView',
    show: true
  },
  {
    name: 'Chats',
    to: 'SupervisionChatView',
    show: true
  },
  {
    name: 'Editar equipo',
    to: 'SupervisionEditTeamView',
    show: authStore.userTeamIds.length > 0
  }
]

const statisticDialog = ref(false)

onMounted(async () => {
  if (authStore.user) {
    const validSession = await authStore.validateUserSession()

    if (validSession !== 'success') {
      router.push({ name: 'ErrorBoundary', params: { resource: 'session' } })
      return
    }
  }
})

rabbitStore.openRabbitConnection()
connectionStore.bindEvents()
taskSocketStore.connect()
</script>

<template>
  <v-layout>
    <v-navigation-drawer
      class="position-fixed"
      style="background-color: #182228"
      rail-width="78"
      v-model="drawer"
      :rail="rail"
      permanent
    >
      <v-list-item class="d-flex" :class="rail ? 'justify-center' : 'justify-start'">
        <template v-slot:append>
          <div v-if="rail" class="small-logo mx-auto mt-6">
            <v-tooltip v-if="rail" text="Expandir menú">
              <template v-slot:activator="{ props }">
                <img
                  src="../assets/img/vack_logo_v.svg"
                  alt="vack"
                  v-bind="props"
                  @click.stop="rail = !rail"
                />
              </template>
            </v-tooltip>
          </div>
          <div v-if="!rail" class="big-logo mt-5">
            <v-tooltip text="Colapsar menú">
              <template v-slot:activator="{ props }">
                <img
                  src="../assets/img/vack_logo_full.svg"
                  alt="Vack"
                  @click.stop="rail = !rail"
                  v-bind="props"
                />
              </template>
            </v-tooltip>
          </div>
        </template>
      </v-list-item>

      <v-list density="compact" class="px-0" nav>
        <v-tooltip
          v-for="item of sidebarItems"
          :key="item.name"
          :disabled="!rail"
          :text="item.name"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              :class="[rail ? 'pl-6' : 'pl-6']"
              v-bind="props"
              class="py-4 position-relative"
              base-color="blue-grey-lighten-1"
              :prepend-icon="item.icon"
              :active="route.name === item.to"
              color="blue-darken-2"
              :title="item.name"
              :value="item.name"
              @click="router.push({ name: item.to })"
            >
              <template v-if="!rail" v-slot:append>
                <v-badge color="red-accent-2" :content="item.content" inline></v-badge>
              </template>
              <div v-if="rail" class="badge-container">
                <v-badge color="red-accent-2" :content="item.content" inline></v-badge>
              </div>
            </v-list-item>
          </template>
        </v-tooltip>

        <v-list-group v-show="hasSupervisionAccess" density="compact" value="Supervision">
          <template v-slot:activator="{ props }">
            <v-list-item
              @click="rail = false"
              :class="[rail ? 'pl-6' : 'pl-6']"
              class="py-4 position-relative"
              color="blue-darken-2"
              v-bind="props"
              :prepend-icon="QueryStatsIcon"
              title="Supervisión"
              base-color="blue-grey-lighten-1"
            >
              <v-tooltip activator="parent" :disabled="!rail">Supervisión</v-tooltip>
            </v-list-item>
          </template>

          <v-list-item
            v-for="(item, i) in supervisionItems"
            :key="i"
            v-show="item.show"
            color="blue-darken-2"
            base-color="blue-grey-lighten-1"
            :title="item.name"
            :value="item.to"
            @click="router.push({ name: item.to })"
          ></v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <v-list density="compact" class="px-0" nav>
          <v-tooltip :disabled="!rail" text="Cerrar sesión">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                :class="[rail ? 'pl-6' : 'pl-6']"
                class="py-4"
                prepend-icon="mdi-logout"
                base-color="blue-grey-lighten-1"
                title="Cerrar sesión"
                @click="statisticDialog = true"
              ></v-list-item>
            </template>
          </v-tooltip>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <RouterView />
    </v-main>
  </v-layout>

  <StatisticModal v-model="statisticDialog" show-ratios />
</template>

<style scoped>
.small-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-logo img {
  max-width: 25px;
  cursor: pointer;
}
.big-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 120px;
  height: 39px;
}
.big-logo img {
  max-height: 100%;
  cursor: pointer;
}
.v-list-item--active {
  border-left: solid 4px;
}
.badge-container {
  position: absolute;
  top: 4px;
  right: 8px;
}
</style>
