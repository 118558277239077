import { apiClient as axios } from '@/axios.config'
import { BACK_URL, BACK_CHAT_URL, CHAT_URL, BACK_WHATSAPP_URL } from '../const/env'
import { requestHandler } from './handleRequest'
import type { ChatOperator, Inbox, Queue, ReasonConversation, Room } from '../definitions/chat'
import type { User } from '../definitions/auth'
import type { Message } from '../definitions/message'
import type { NewMessage } from '../composables/useMessage'

export const getQueues = requestHandler<undefined, Queue[]>(() =>
  axios.get(`${CHAT_URL}/queue/inbox_queue/`)
)

type ActionParams = {
  userChatId: User['user_chat_id']
  queueId: Queue['id']
}

export const assignToQueue = requestHandler<ActionParams, Queue>((params) =>
  axios.post(`${CHAT_URL}/queue/inbox_queue/${params?.queueId}/add_operator/`, {
    operator_id: params?.userChatId
  })
)

export const removeFromQueue = requestHandler<ActionParams, Queue>((params) =>
  axios.post(`${CHAT_URL}/queue/inbox_queue/${params?.queueId}/remove_operator/`, {
    operator_id: params?.userChatId
  })
)

type RoomParams = {
  operatorId: string | number
}

export const getOperatorRoom = requestHandler<RoomParams, Room>((params) =>
  axios.get(`${CHAT_URL}/queue/inbox_queue/inbox/?operator_id=${params?.operatorId}`)
)

type SearchParam = {
  search: string
}

export const searchInbox = requestHandler<SearchParam, Inbox[]>((params) =>
  axios.get(`${CHAT_URL}/queue/inbox_queue/search/?search=${params?.search}`)
)

export const changeAssignedRoomOperator = requestHandler<{ uri: string; operatorID: number }, Room>(
  (params) => {
    return axios.post(`${CHAT_URL}/queue/room/${params?.uri}/change_operator/`, {
      operator_id: params?.operatorID
    })
  }
)

export const unnasignOperatorFromRoom = requestHandler<{ uri: string }, Room>((params) =>
  axios.post(`${CHAT_URL}/queue/room/${params?.uri}/unassign_operator/`, {})
)

export const changeAssignedRoomQueue = requestHandler<{ uri: string; queueID: number }, Room>(
  (params) =>
    axios.post(`${CHAT_URL}/queue/room/${params?.uri}/change_queue/`, {
      queue_id: params?.queueID
    })
)

export const fetchOperators = requestHandler<undefined, ChatOperator[]>(() =>
  axios.get(`${BACK_URL}/chat_operator/`)
)

export const searchQueueByName = requestHandler<{ name: string }, Queue[]>((params) =>
  axios.get(`${CHAT_URL}/queue/inbox_queue/?name=${params?.name}`)
)

// marcar inbox como importante o no importante
export const markInboxAsImportant = requestHandler<{ uri: string; important: boolean }, Inbox>(
  (params) =>
    axios.post(`${CHAT_URL}/queue/room/${params?.uri}/mark_as_important/`, {
      important: params?.important
    })
)

export const setInboxStatusToAwaitingReply = requestHandler<{ uri: string }, Inbox>((params) =>
  axios.post(`${CHAT_URL}/queue/room/${params?.uri}/set_on_pause/`)
)

export const fetchChatMessages = requestHandler<{ uriChat: string }, { messages: Message[] }>(
  (params) => axios.get(`${CHAT_URL}/chats/${params?.uriChat}/messages/`)
)

export const createMessage = requestHandler<{ message: NewMessage }, any>((params) =>
  axios.post(`${BACK_CHAT_URL}/rooms/add_message`, params?.message)
)

export const checkChatRoomBotStatus = requestHandler<{ customerID: number; origen: string }, any>(
  (params) =>
    axios.get(`${BACK_WHATSAPP_URL}/bot_status/${params?.customerID}/?origen=${params?.origen}`)
)

export const updateChatRoomBotStatus = requestHandler<
  { uri: string; status: 'open' | 'close'; agentID: number; requestID: number },
  any
>((params) =>
  axios.put(`${BACK_WHATSAPP_URL}/history_conversation/${params?.uri}/`, {
    status: params?.status,
    user_id: params?.agentID,
    request_id: params?.requestID
  })
)

export const openOrCloseConversation = requestHandler<
  { uri: string; requestID: number; reasonName?: string; reasonID?: number; agentID: number },
  any
>((params) => {
  let body

  if (params?.reasonID) {
    body = {
      request_id: params?.requestID,
      reason: params?.reasonID,
      user_id: params?.agentID
    }
  } else if (params?.reasonName) {
    body = {
      request_id: params?.requestID,
      reason_name: params?.reasonName,
      user_id: params?.agentID
    }
  }

  return axios.put(`${BACK_WHATSAPP_URL}/history_conversation/${params?.uri}/`, body)
})

export const fetchStatusConversation = requestHandler<{ uri: string }, any>((params) =>
  axios.get(`${BACK_WHATSAPP_URL}/history_conversation/${params?.uri}/`)
)

export const fetchConversationReasons = requestHandler<undefined, ReasonConversation[]>(() =>
  axios.get(`${BACK_WHATSAPP_URL}/reason/`)
)

export const updateBotActiveStatus = requestHandler<{ uri: string; status: string }, any>(
  (params) => axios.put(`${BACK_CHAT_URL}/rooms/${params?.uri}/bot/`, { status: params?.status })
)

export const checkBotCondition = requestHandler<{ uri: string }, any>((params) =>
  axios.get(`${BACK_CHAT_URL}/room_bot_data/${params?.uri}/`)
)

export const fetchClientHsm = requestHandler<
  { clientUserID: string | null; requestID: number; hasSessionActive: boolean },
  any
>((params) =>
  axios.get(
    `${BACK_URL}/template_msj/?request_id=${params?.requestID}&client_user_id=${params?.clientUserID}&has_session_active=${params?.hasSessionActive}`
  )
)

export const fetchHsm = requestHandler<{ requestID: number; hasSessionActive: boolean }, any>(
  (params) => {
    return axios.get(
      `${BACK_URL}/template_msj/?request_id=${params?.requestID}&has_session_active=${params?.hasSessionActive}`
    )
  }
)
