import { defineStore } from 'pinia'
import {
  StatusConversation,
  type Inbox,
  type HsmTemplateGroup,
  type ReasonConversation
} from '../definitions/chat'
import { computed, onUnmounted, ref, watchEffect } from 'vue'
import { useAuthStore } from './auth'

import {
  checkChatRoomBotStatus,
  fetchClientHsm,
  fetchConversationReasons,
  fetchHsm,
  fetchOperators,
  fetchStatusConversation,
  getQueues,
  openOrCloseConversation,
  unnasignOperatorFromRoom,
  updateBotActiveStatus,
  updateChatRoomBotStatus
} from '../services/chat'
import { useChatStore } from './chat'
import { inboxHasWhatsapp } from '../lib/utils'
import { AxiosError } from 'axios'

const OPENING_CONVERSATION_REASON = 'Inicio Gestion'
const CLOSE_TYPE = 'close'

export const useInboxStore = defineStore('inbox', () => {
  const authStore = useAuthStore()
  const chatStore = useChatStore()

  const inbox = ref<Inbox[]>([])
  const selectedInbox = ref<Inbox | null>(null)
  const hsmTemplates = ref<HsmTemplateGroup[]>([])
  const reasonsForClosing = ref<ReasonConversation[]>([])

  const isAwaitingForReply = computed(() => {
    return (id: number) =>
      inbox.value.find((chat) => chat.id === id)?.conversation_status === StatusConversation.paused
  })

  const hasAccessToBot = computed(() => {
    return inboxHasWhatsapp(selectedInbox.value?.type_room ?? 0)
  })

  watchEffect(async () => {
    if (selectedInbox.value) {
      const { uri, assigned_user } = selectedInbox.value
      chatStore.markAsRead({ uriChat: selectedInbox.value.uri })
      chatStore.connectOperatorToRoom({
        uriChat: uri,
        userChatID: assigned_user
      })
      await chatStore.getChatMessages({ uriChat: uri })
      await getHSMTemplates()

      if (hasAccessToBot.value) {
        await validateChatRoomBotStatus({ inbox: selectedInbox.value })
      }
    }
  })

  const showModal = ref(false)
  const scoreInterval = setInterval(increaseScorePerMinute, 60000)

  onUnmounted(() => {
    clearInterval(scoreInterval)
  })

  const unreadInboxs = computed(() => {
    const unread = inbox.value.filter((chat) => {
      const member = chat.members.find((member) => member.user === chat.assigned_user)

      if (!member) return 0

      return member.unread > 0
    }).length

    return unread
  })

  const activeConversations = computed(() => {
    return inbox.value.filter((chat) => chat.conversation_status === StatusConversation.active)
  })

  const pausedConversations = computed(() => {
    return inbox.value.filter((chat) => chat.conversation_status === StatusConversation.paused)
  })

  function increaseScorePerMinute() {
    inbox.value = inbox.value.map((chat) => {
      return { ...chat, sorting_score: chat.sorting_score + 1 }
    })
  }

  function closeModal() {
    showModal.value = false
    disconnectChatSession()
  }

  function disconnectChatSession() {
    if (!selectedInbox.value) return
    chatStore.disconnectOperatorFromRoom({ uriChat: selectedInbox.value.uri })
    chatStore.chatMessages = []
    selectedInbox.value = null
    hsmTemplates.value = []
    reasonsForClosing.value = []
  }

  async function getOperators() {
    try {
      const response = await fetchOperators()
      if (response.code === 'error') throw response.error
      const operators = response.data

      const filteredOperators = operators
        .filter(
          (operator) =>
            operator.working_status === 'ONLINE' || operator.working_status === 'EN PAUSA'
        )
        .filter((operator) => operator.user_chat !== authStore.userChatId)

      return filteredOperators
    } catch (error) {
      console.log(error)
    }
  }

  async function fetchQueues() {
    try {
      const response = await getQueues()
      if (response.code === 'error') throw response.error
      const queues = response.data

      const filteredQueues = queues.filter((queue) => queue.is_active)

      return filteredQueues
    } catch (error) {
      console.log(error)
    }
  }

  async function validateChatRoomBotStatus({ inbox }: { inbox: Inbox }) {
    try {
      if (!inbox) return

      const { customer_id, origen_whatsapp, status_conversation, uri } = inbox

      if (customer_id === null || origen_whatsapp === null) {
        console.error('No se encontró el customer_id o el origen_whatsapp')
      }

      const response = await checkChatRoomBotStatus({
        customerID: customer_id,
        origen: origen_whatsapp
      })

      if (response.code === 'error') throw response.error

      const botCondition = response.data

      if (!botCondition.is_bot_muted && status_conversation === 'open') {
        await updateInboxStatusConversation({ uri: uri, status: 'close' })
      } else if (botCondition.is_bot_muted && status_conversation === 'close') {
        await updateInboxStatusConversation({ uri: uri, status: 'close' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function updateInboxStatusConversation({
    uri,
    status
  }: {
    uri: string
    status: 'close' | 'open'
  }) {
    if (!selectedInbox.value) return

    const requestID = selectedInbox.value?.request_id
    const agentID = authStore.user?.id

    if (!requestID || !agentID || !uri || !status)
      throw new Error('No se encontró el requestID, agentID, uri o status')

    try {
      const response = await updateChatRoomBotStatus({ uri, status, requestID, agentID })
      if (response.code === 'error') throw response.error
    } catch (error) {
      console.log(error)
    }

    selectedInbox.value.status_conversation = status
  }

  async function getStatusConversation(uri: string) {
    if (!selectedInbox.value) return

    try {
      const response = await fetchStatusConversation({ uri })
      if (response.code === 'error') throw response.error
      selectedInbox.value.status_conversation = 'open'
    } catch (error) {
      selectedInbox.value.status_conversation = 'close'
      if (error instanceof AxiosError) {
        console.log('Error al obtener estado de la conversación', error?.response?.data?.message)
      }
    }
  }

  async function initializeConversation({ inbox }: { inbox: Inbox }) {
    const { request_id: requestID, uri } = inbox
    const agentID = authStore.user?.id
    const reasonName = OPENING_CONVERSATION_REASON

    if (!requestID || !uri || !agentID || !reasonName) {
      throw new Error('No se encontró el requestID, uri, agentID o reasonName')
    }

    try {
      const response = await openOrCloseConversation({ uri, requestID, agentID, reasonName })
      if (response.code === 'error') throw response.error
    } catch (error) {
      console.log(error)
    }

    if (inbox.status_conversation === 'open') {
      await handleBotActiveProperty({ uri, status: 'enable' })
    } else {
      await handleBotActiveProperty({ uri, status: 'disable' })
    }

    await getStatusConversation(uri)
  }

  async function closeConversation({
    inbox,
    reason
  }: {
    inbox: Inbox
    reason: ReasonConversation
  }) {
    const { request_id: requestID, uri } = inbox
    const agentID = authStore.user?.id
    const reasonID = reason.id

    if (!requestID || !uri || !agentID || !reasonID) {
      throw new Error('No se encontró el requestID, uri, agentID o reasonID')
    }

    try {
      const response = await openOrCloseConversation({ uri, requestID, agentID, reasonID })
      if (response.code === 'error') throw response.error
    } catch (error) {
      console.log(error)
    }

    try {
      const response = await unnasignOperatorFromRoom({ uri })
      if (response.code === 'error') throw response.error
    } catch (error) {
      console.log({ error })
    }

    if (inbox.status_conversation === 'open') {
      await handleBotActiveProperty({ uri, status: 'enable' })
    } else {
      await handleBotActiveProperty({ uri, status: 'disable' })
    }

    await getStatusConversation(uri)
  }

  async function handleBotActiveProperty({
    uri,
    status
  }: {
    uri: string
    status: 'enable' | 'disable'
  }) {
    if (!selectedInbox.value) return

    try {
      const response = await updateBotActiveStatus({ uri, status })
      if (response.code === 'error') throw response.error

      if (status === 'enable') {
        selectedInbox.value.bot_active = true
      }

      if (status === 'disable') {
        selectedInbox.value.bot_active = false
      }
    } catch (error) {
      console.log('Error al actualizar el estado del bot', error)
    }
  }

  async function getHSMTemplates() {
    if (selectedInbox.value === null) return

    const { request_id, client_user_id, has_session_active } = selectedInbox.value

    try {
      if (inboxHasWhatsapp(selectedInbox.value.type_room)) {
        const response = await fetchClientHsm({
          requestID: request_id,
          clientUserID: client_user_id,
          hasSessionActive: has_session_active
        })

        if (response.code === 'error') throw response.error

        hsmTemplates.value = response.data
      } else {
        const response = await fetchHsm({
          requestID: request_id,
          hasSessionActive: has_session_active
        })

        if (response.code === 'error') throw response.error

        hsmTemplates.value = response.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getClosedStatusConversationReasons() {
    try {
      const response = await fetchConversationReasons()
      if (response.code === 'error') throw response.error
      const reasons = response.data

      reasonsForClosing.value = reasons.filter((reason) => reason.type === CLOSE_TYPE)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    inbox,
    selectedInbox,
    hsmTemplates,
    reasonsForClosing,
    hasAccessToBot,
    showModal,
    isAwaitingForReply,
    activeConversations,
    pausedConversations,
    unreadInboxs,
    getOperators,
    getClosedStatusConversationReasons,
    getQueues: fetchQueues,
    initializeConversation,
    closeConversation,
    getHSMTemplates,
    closeModal,
    disconnectChatSession
  }
})
