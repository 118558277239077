import { defineStore } from 'pinia'
import { socket } from '@/socket'
import type { Message } from '../definitions/message'
import { useChatStore } from './chat'

type SocketMessage =
  | {
      join: string
      user: number
      socket: string
    }
  | {
      msg_type: number
      uri: string
      request_id: number
      title: string
      description: string
      message: Message
    }

export const useConnectionStore = defineStore('connection', {
  state: () => ({
    isConnected: false,
    socketMessage: null
  }),
  actions: {
    bindEvents() {
      socket.on('connect', () => {
        this.isConnected = true
      })

      socket.on('disconnect', () => {
        this.isConnected = false
      })

      socket.on('message', (data: SocketMessage) => {
        this.handleSocketMessage(data)
      })
    },

    handleSocketMessage(message: SocketMessage) {
      const chatStore = useChatStore()

      if ('join' in message) {
        console.log('User joined:', message)
      } else if ('msg_type' in message) {
        switch (message.msg_type) {
          case 0:
            chatStore.appendMessage(message.message)
            break
          default:
            break
        }
      }
    },

    connect() {
      socket.connect()
    }
  }
})
