import { apiClient as axios } from '@/axios.config'
import { BACK_URL, CHAT_URL } from '../const/env'
;('http://localhost/v1/backend/operator_task_stats/111/')

export interface TaskStatistics {
  id: number
  first_name: string
  last_name: string
  statistics: TaskStatisticDetail
  porcentajes_tareas?: TaskStatisticRatios
}

export interface TaskStatisticDetail {
  operator: number
  tareas_realizadas: number
  tareas_asignadas: number
  cantidad_postergaciones: number
  promedio_finalizacion: number
}

export interface TaskStatisticRatios {
  porcentaje_tareas_realizadas: number
  porcentaje_tareas_asignadas: number
  porcentaje_cantidad_postergaciones: number
  porcentaje_promedio_finalizacion: number
}

export const fetchTaskStatistics = async (id: number) => {
  try {
    const response = await axios.get<TaskStatistics>(`${BACK_URL}/operator_task_stats/${id}/`)
    const taskStatistics = response.data
    return taskStatistics
  } catch (error) {
    console.log('Error fetching task statistics')
    throw error
  }
}

export const fetchClosingTaskStatistics = async (id: number) => {
  try {
    const response = await axios.get<TaskStatistics>(
      `${BACK_URL}/operator_task_stats/${id}/?closing=true`
    )
    const taskStatistics = response.data
    return taskStatistics
  } catch (error) {
    console.log('Error fetching closing task statistics')
    throw error
  }
}

export interface ChatStatistics {
  id: number
  first_name: string
  last_name: string
  statistics: ChatStatisticsDetail
  porcentajes_chat?: ChatStatisticsRatios
}

export interface ChatStatisticsDetail {
  chats_cerrados: number
  chats_asignados: number
  promedio_respuesta: number
  chats_no_respondidos: number
}

export interface ChatStatisticsRatios {
  porcentaje_chats_cerrados: number
  porcentaje_chats_asignados: number
  porcentaje_promedio_respuesta: number
  porcentaje_chats_no_respondidos: number
}

export const fetchChatStatistics = async (id: number) => {
  try {
    const response = await axios.get<ChatStatistics>(`${CHAT_URL}/queue/operator_chat_stats/${id}/`)
    const chatStatistics = response.data
    return chatStatistics
  } catch (error) {
    console.log('Error fetching chat statistics')
    throw error
  }
}

export const fetchClosingChatStatistics = async (id: number) => {
  try {
    const response = await axios.get<ChatStatistics>(
      `${CHAT_URL}/queue/operator_chat_stats/${id}/?closing=true`
    )
    const chatStatistics = response.data
    return chatStatistics
  } catch (error) {
    console.log('Error fetching chat statistics')
    throw error
  }
}
