import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { fetchRequestTaskInformation, fetchTasksByStatus, type Task } from '../services/tasks'

export const useTaskStore = defineStore('tasks', () => {
  const pagination = ref({
    search: '',
    status: ''
  })

  const controller = ref<AbortController>()
  const tasks = ref<Task[]>([])
  const taskStatus = ref<'idle' | 'loading' | 'success' | 'error'>('idle')
  const tasksIsLoading = computed(() => taskStatus.value === 'loading')
  const tasksToDo = computed(
    () => tasks.value.filter((task) => task.custom_status === 'PENDING').length
  )

  async function getTasks({ pagination }: { pagination: { status: string; search?: string } }) {
    taskStatus.value = 'loading'
    if (!pagination || !pagination.status) return

    if (controller.value) {
      controller.value.abort()
    }

    controller.value = new AbortController()
    const signal = controller.value.signal

    try {
      const response = await fetchTasksByStatus({ pagination, signal })
      if (response.code === 'error') throw response.error

      const allTasks = response.data
      const taskRequestIds = allTasks.map((task) => task.request_id)

      const taskInformationResponse = await fetchRequestTaskInformation({ idList: taskRequestIds })
      if (taskInformationResponse.code === 'error') throw taskInformationResponse.error

      const taskInformation = taskInformationResponse.data

      // Create a Map for quick lookup
      const taskInfoMap = new Map(taskInformation.map((info) => [info.object_id, info]))

      // Update tasks with additional information
      allTasks.forEach((task) => {
        const additionalInfo = taskInfoMap.get(task.request_id)
        if (additionalInfo) {
          task.request_queue_name = additionalInfo.queue_name
          task.request_unread_messages = additionalInfo.unread_messages_count
          task.chat_sessions = additionalInfo.sessions
          task.stage_times = additionalInfo.stage_times
        }
      })

      tasks.value = allTasks
      taskStatus.value = 'success'
    } catch (error) {
      taskStatus.value = 'error'
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('Request canceled', error.message)
      } else {
        console.error(error)
      }
    }
  }

  async function upsertTask({ task }: { task: Task }) {
    try {
      const draft = [...tasks.value]
      const index = draft.findIndex((t) => t.id === task.id)
      if (index === -1) {
        const chatResponse = await fetchRequestTaskInformation({ idList: [task.request_id] })
        if (chatResponse.code === 'error') throw chatResponse.error
        const chatSessionsData = chatResponse.data
        const formattedTask = {
          ...task,
          chat_sessions: chatSessionsData[0].sessions,
          request_queue_name: chatSessionsData[0].queue_name,
          request_unread_messages: chatSessionsData[0].unread_messages_count
        }
        draft.push(formattedTask)
      } else {
        draft[index] = task
      }
      tasks.value = draft
    } catch (error) {
      console.error(error)
    }
  }

  function deleteTask(task: Task) {
    const index = tasks.value.findIndex((t) => t.id === task.id)
    if (index !== -1) {
      tasks.value.splice(index, 1)
    }
  }

  return { tasks, tasksToDo, tasksIsLoading, pagination, getTasks, upsertTask, deleteTask }
})
