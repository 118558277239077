import { ref, computed, toRaw } from 'vue'
import { defineStore } from 'pinia'
import { useConnectionStore } from './socket-connection'
import { socket } from '@/socket'
import {
  fetchChatMessages,
  markInboxAsImportant,
  setInboxStatusToAwaitingReply
} from '../services/chat'
import type { Message } from '../definitions/message'
import { useInboxStore } from './inbox'
import { AxiosError } from 'axios'
import { useAuthStore } from './auth'

export const useChatStore = defineStore('chat', () => {
  const connectionStore = useConnectionStore()
  const inboxStore = useInboxStore()
  const authStore = useAuthStore()

  const chatMessages = ref<Message[]>([])
  const messagesStatus = ref<'idle' | 'loading' | 'success' | 'error'>('idle')
  const messagesLoading = computed(() => {
    return messagesStatus.value === 'loading'
  })

  const isLastMessageFromOperator = computed(() => {
    if (!chatMessages.value.length) return false
    const lastMessage = chatMessages.value[chatMessages.value.length - 1]
    return lastMessage.user.id === authStore.userChatId
  })

  const filteredMessages = computed(() => {
    if (!chatMessages.value.length) return []

    const messages = chatMessages.value

    return messages.map((message, index, messages) => {
      const messageDate = message?.date ?? undefined
      const newFormatMessage = { ...message, auxDate: messageDate }

      if (messages.length === 1) return newFormatMessage

      if (messages.length > 1) {
        if (index === 0) {
          return newFormatMessage
        } else {
          const previousMessage = messages[index - 1]

          if (previousMessage.date === messageDate) {
            if (previousMessage.user.id === message.user.id) {
              message.user.first_name = ''
            }

            return { ...message, auxDate: undefined }
          }
        }
      }

      return newFormatMessage
    })
  })

  async function getChatMessages({ uriChat }: { uriChat: string }) {
    messagesStatus.value = 'loading'
    try {
      const response = await fetchChatMessages({ uriChat })
      if (response.code === 'error') throw response.error
      chatMessages.value = response.data.messages
      messagesStatus.value = 'success'
    } catch (error) {
      console.log(error)
      messagesStatus.value = 'error'
    }
  }

  function appendMessage(message: Message) {
    if (inboxStore.selectedInbox?.assigned_user === message.user.id) return
    chatMessages.value.push(message)
  }

  function connectOperatorToRoom({ uriChat, userChatID }: { uriChat: string; userChatID: number }) {
    if (!connectionStore.isConnected) return
    if (!uriChat || !userChatID) return

    socket.emit('join', {
      room: uriChat,
      user_id: userChatID
    })
  }

  function disconnectOperatorFromRoom({ uriChat }: { uriChat: string }) {
    if (!connectionStore.isConnected) return
    if (!uriChat) return

    socket.emit('leave', { room: uriChat })
  }

  async function toggleImportantStatus() {
    if (inboxStore.selectedInbox === null) return
    const inbox = structuredClone(toRaw(inboxStore.selectedInbox))

    try {
      const uri = inbox.uri
      inbox.important = !inbox.important
      const response = await markInboxAsImportant({ uri, important: inbox.important })
      if (response.code === 'error') throw response.error
      inboxStore.selectedInbox.important = inbox.important
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data
      }
    }
  }

  async function moveOnToAwaitingReply() {
    try {
      if (!inboxStore.selectedInbox) return

      const lastMessage = chatMessages.value[chatMessages.value.length - 1]
      if (lastMessage.user.id !== authStore.userChatId) return

      const uri = inboxStore.selectedInbox.uri
      const response = await setInboxStatusToAwaitingReply({ uri })
      if (response.code === 'error') throw response.error
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data
      }
    }
  }

  function markAsRead({ uriChat }: { uriChat: string }) {
    if (!uriChat || !authStore.userChatId) return
    socket.emit('read', { uri: uriChat, user_id: authStore.userChatId })
  }

  return {
    chatMessages,
    messagesLoading,
    isLastMessageFromOperator,
    filteredMessages,
    moveOnToAwaitingReply,
    toggleImportantStatus,
    connectOperatorToRoom,
    disconnectOperatorFromRoom,
    getChatMessages,
    appendMessage,
    markAsRead
  }
})
