import { computed, ref } from 'vue'
import type { User } from '../definitions/auth'
import {
  fetchChatStatistics,
  fetchClosingChatStatistics,
  fetchClosingTaskStatistics,
  fetchTaskStatistics,
  type ChatStatistics,
  type TaskStatistics
} from '../services/statistics'

type State = 'loading' | 'error' | 'success'

export const useStatistics = () => {
  const statisticsState = ref<State>()
  const chatStatistics = ref<ChatStatistics['statistics']>()
  const taskStatistics = ref<TaskStatistics['statistics']>()
  const statisticsLoading = computed(() => statisticsState.value === 'loading')

  async function getStatistics(user: User) {
    const { get_operator_id: operatorBackId, user_chat_id: operatorChatId } = user
    if (!operatorBackId || !operatorChatId) throw new Error('Unauthorized user')

    try {
      statisticsState.value = 'loading'

      const [tStatistics, cStatistics] = await Promise.allSettled([
        fetchTaskStatistics(operatorBackId),
        fetchChatStatistics(operatorChatId)
      ])

      if (tStatistics.status === 'rejected') {
        console.log(tStatistics.reason)
      } else {
        taskStatistics.value = tStatistics.value.statistics
      }

      if (cStatistics.status === 'rejected') {
        console.log(cStatistics.reason)
      } else {
        chatStatistics.value = cStatistics.value.statistics
      }

      statisticsState.value = 'success'
    } catch (error) {
      console.log(error)
      statisticsState.value = 'error'
    }
  }

  const closingStatisticState = ref<State>()
  const closingChatStatistics = ref<ChatStatistics>()
  const closingTaskStatistics = ref<TaskStatistics>()
  const closingStatisticsLoading = computed(() => closingStatisticState.value === 'loading')

  async function getClosingStatistics(user: User) {
    const { get_operator_id: operatorBackId, user_chat_id: operatorChatId } = user
    if (!operatorBackId || !operatorChatId) throw new Error('Unauthorized user')

    try {
      closingStatisticState.value = 'loading'

      const [tStatistics, cStatistics] = await Promise.allSettled([
        fetchClosingTaskStatistics(operatorBackId),
        fetchClosingChatStatistics(operatorChatId)
      ])

      if (tStatistics.status === 'rejected') {
        console.log(tStatistics.reason)
      } else {
        closingTaskStatistics.value = tStatistics.value
      }

      if (cStatistics.status === 'rejected') {
        console.log(cStatistics.reason)
      } else {
        closingChatStatistics.value = cStatistics.value
      }

      closingStatisticState.value = 'success'
    } catch (error) {
      console.log(error)
      closingStatisticState.value = 'error'
    }
  }

  return {
    taskStatistics,
    chatStatistics,
    statisticsLoading,
    closingTaskStatistics,
    closingChatStatistics,
    closingStatisticsLoading,
    getStatistics,
    getClosingStatistics
  }
}
