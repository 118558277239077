import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'

const apiClient: AxiosInstance = axios.create({
  headers: {
    'Content-type': 'application/json'
  }
})

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const user = sessionStorage.getItem('vackUser')

  const parsedUser = user ? JSON.parse(user) : null

  if (parsedUser && config.headers) {
    if (
      config.url?.includes('/v1/backend/') ||
      config.url?.includes('/v1/chat/') ||
      config.url?.includes('/v1/whatsapp/')
    ) {
      config.headers.Authorization = `JWT ${parsedUser.token}`
    } else {
      const auth = parsedUser.chat_token
      config.headers.Authorization = `${auth.token_type} ${auth.access_token}`
    }
  }

  return config
})

export { apiClient }
