import { apiClient as axios } from '@/axios.config'
import { BACK_URL } from '../const/env'
import type { User, ValidatedUser } from '../definitions/auth'
import type { AxiosResponse } from 'axios'
import { requestHandler } from './handleRequest'

export const autologin = ({ token }: { token: string }): Promise<AxiosResponse<User>> => {
  return axios.post(`${BACK_URL}/login/`, { app_v2_autologin_token: token })
}

export const validateToken = requestHandler<undefined, ValidatedUser>(() =>
  axios.get(`${BACK_URL}/verify_token/`)
)
