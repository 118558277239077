export interface Queue {
  id: number
  name: string
  exchange_name: string
  tiempo_inicio_temperatura_amarilla: number
  tiempo_inicio_temperatura_roja: number
  tiempo_primer_interaccion_operador: number
  tiempo_de_espera_interaccion_cliente: number
  tiempo_de_espera_interaccion_operador: number
  operadores: Operator[]
  is_active: boolean
}

export interface Operator {
  id: number
  full_name: string
}

export interface Room {
  exchange_list: string[]
  inbox: Inbox[]
}

export interface Inbox {
  id: number
  uri: string
  last_date_message: any
  title: string
  last_message: any
  queue_name: any
  assigned_user: number
  use_temperature_scale: boolean
  conversation_status: string
  members: Member[]
  request_id: any
  request_external_id: any
  client_tags: any
  client_whatsapp_phone: any
  request_organization: any
  important: boolean
  request_featured: any
  sorting_score: number
  type_room: number
  operator_inactivity_eta: any
  assigned_user_timestamp: string
  related_request: RelatedRequest[]
  assigned_user_fullname: string
  customer_id: any
  origen_whatsapp: any
  status_conversation: string
  has_session_active: boolean
  has_bot: boolean
  bot_active: boolean
  client_user_id: string | null
  validated_number: boolean
}

export interface LastMessage {
  id: number
  message: string
  status: number
}

export interface Member {
  user: number
  unread: number
}

export interface ClientTags {
  acronyms: string
}

export type RequestScheme = 'Asistencias' | 'Siniestros' | 'Reintegro'
export type RequestStatus = 'En curso' | 'Finalizado' | 'Cancelado'
export interface RelatedRequest {
  id: number
  create_date: string
  update_date: string
  object_id: number
  esquema: RequestScheme
  dni_cliente: string
  incidencia_en_curso: boolean
  visita_confirmada: boolean
  fecha_cierre: any
  id_externo: string
  favorito: boolean
  tags_cliente: TagsCliente[]
  organizacion: string
  estado: RequestStatus
  descripcion_servicio: string
  created_time: any
  updated_time: any
  nro_poliza: number
  assigned_user: number
  cliente: any
  chat_session: number[]
}

export interface TagsCliente {}

export enum StatusConversation {
  active = 'CONVERSACION ACTIVA',
  paused = 'CONVERSACION EN PAUSA'
}
export interface ChatOperator {
  id: number
  working_status: string
  full_name: string
  user_chat: number
}

export interface HsmTemplateGroup {
  id: number
  group_title: string
  group_templates: Template[]
}

export interface Template {
  id: number
  title: string
  name_hsm: string
  message: string
  parameters: string[]
  available_for_chat: string[]
}

export interface ReasonConversation {
  id: number
  description: string
  type: string
  has_survey: boolean
}
