import { apiClient as axios } from '@/axios.config'
import { requestHandler } from './handleRequest'
import { BACK_URL, CHAT_URL } from '../const/env'
import type { Inbox } from '../definitions/chat'

export interface Task {
  created_time: string
  id: number
  operator: any
  title: string
  request_scheme: string
  request_tags: any[]
  organization_name: string
  request_id: number
  request_rubro: any
  request_external_id: any
  is_urgency: boolean
  is_new: boolean
  request_featured: any
  custom_status: string
  resolution_time: any // tiempo que finalizo la tarea pasar a formato de tiempo
  start_time: any // ETA de las tareas postergadas
  expiration_time: string // ETA de las tareas pendientes
  expiration_colors: ExpirationColors // tiempos de cada franja
  request_stage_time: any
  request_queue_name: string
  request_unread_messages: number
  rescheduled_count: number
  chat_sessions?: Inbox[]
  stage_times?: {
    inicio_etapa: string | null
    fin_etapa: string | null
    fin_pedido: string | null
  }
}

export interface ExpirationColors {
  NEW: number
  YELLOW: number
  RED: number
}

export const fetchTasksByStatus = requestHandler<
  {
    pagination: { status: string; search?: string }
    signal: AbortSignal
  },
  Task[]
>((params) => {
  const pagination = params?.pagination
  let query = ''
  if (pagination?.search && pagination.status) {
    query += `search=${pagination.search}&status=${pagination.status}`
  }
  const url = query !== '' ? `${BACK_URL}/task/?${query}` : `${BACK_URL}/task/`
  return axios.get(url, { signal: params?.signal })
})

interface AdditionalTaskInformation {
  object_id: number
  queue_name: string
  unread_messages_count: number
  sessions: Inbox[]
  stage_times: {
    inicio_etapa: string | null
    fin_etapa: string | null
    fin_pedido: string | null
  }
}

export const fetchRequestTaskInformation = requestHandler<
  { idList: number[] },
  AdditionalTaskInformation[]
>((params) => {
  const url = `${CHAT_URL}/queue/requestmin/chats_info/`
  return axios.post(url, { request_list: params?.idList })
})
