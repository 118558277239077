<script setup lang="ts">
import { shallowRef } from 'vue'
import { RouterView } from 'vue-router'
import router from '@/router'
import layouts from '@/const/layouts'
import type { Component } from 'vue'
import { loadClarityScript } from './lib/clarity'

const layout = shallowRef<Component | string>('div')

router.beforeEach((to) => {
  layout.value = layouts[to.meta.layout as keyof typeof layouts] || 'div'
})

const clarityId = import.meta.env.VITE_CLARITY_ID

if (clarityId) {
  loadClarityScript(clarityId)
}
</script>

<template>
  <v-app>
    <component :is="layout || 'div'">
      <RouterView />
    </component>
  </v-app>
</template>

<style></style>
