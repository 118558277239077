<script setup lang="ts">
type RatioItem = { key: string; value: number }

defineProps<{
  icon: any
  statistic: string
  statisticAmount: number | string
  showRatios: boolean
  ratio?: { key: string; value: number }
}>()

const getStatsIcon = (ratio: RatioItem) => {
  if (ratio.value === 0) return 'mdi-equal'
  if (ratio.value < 0) return 'mdi-arrow-down'
  if (ratio.value > 0) return 'mdi-arrow-up'
}

const getStatsColor = (ratio: RatioItem) => {
  if (ratio.value === 0) return 'blue-darken-2'
  switch (ratio.key) {
    case 'porcentaje_tareas_realizadas':
    case 'porcentaje_tareas_asignadas':
    case 'porcentaje_chats_cerrados':
    case 'porcentaje_chats_asignados':
      return ratio.value > 0 ? 'green' : 'red-accent-2'
    case 'porcentaje_promedio_finalizacion':
    case 'porcentaje_cantidad_postergaciones':
    case 'porcentaje_promedio_respuesta':
    case 'porcentaje_chats_no_respondidos':
      return ratio.value < 0 ? 'green' : 'red-accent-2'
    default:
      return 'blue-darken-2'
  }
}
</script>
<template>
  <div>
    <v-card class="border-sm rounded-lg" elevation="0">
      <v-row>
        <v-col class="d-flex align-center">
          <v-card-title>
            <v-icon color="blue-darken-2" size="small" :icon="icon"></v-icon>
          </v-card-title>
          <v-card-text class="font-weight-bold text-subtitle-1 py-4 pl-0">
            <h5 class="text-h5 text-blue-grey-darken-2 font-weight-bold">
              {{ statisticAmount }}
            </h5>
            <span class="text-subtitle-1" style="opacity: 60%">{{ statistic }}</span>
          </v-card-text>
          <v-card-title class="align-self-start" v-if="showRatios && ratio">
            <span class="text-caption font-weight-bold" :class="'text-' + getStatsColor(ratio)">{{
              ratio.value > 0 ? `+${ratio.value}%` : `${ratio.value}%`
            }}</span>
            <v-icon
              :color="getStatsColor(ratio)"
              size="x-small"
              :icon="getStatsIcon(ratio)"
            ></v-icon>
          </v-card-title>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style scoped></style>
