import { defineStore } from 'pinia'
import { computed, provide, ref, watch } from 'vue'
import type {
  Campaign,
  Operator,
  QueueStatistic,
  StatisticDetailWithQueues
} from '../definitions/supervision'

import { queueTimerKey } from '../lib/keys'
import { fetchStatisticDetails, fetchStatisticQueues } from '../services/supervision'
import { useRabbitStore } from './rabbit'

export const useSupervisionStore = defineStore('supervision', () => {
  const rabbitStore = useRabbitStore()

  const queues = ref<QueueStatistic[]>([])
  const tab = ref<number | null>(null)
  const currentQueue = computed(() => queues.value?.find((queue) => queue.id === tab.value))
  const selectedCampaigns = ref<string[]>([])
  const selectedOperators = ref<number[]>([])
  const campaigns = ref<Campaign[]>([])
  const operators = ref<Operator[]>([])

  watch(
    tab,
    (newTab) => {
      if (newTab == null) return
      getStatisticDetail({ queueID: newTab })
      selectedCampaigns.value = []
      selectedOperators.value = []
    },
    { immediate: true }
  )

  watch(
    currentQueue,
    async (newValue, oldValue) => {
      if (!rabbitStore.client?.connected) return

      if (newValue && oldValue == null) {
        rabbitStore.subscribeToExchange({
          exchangeName: newValue.stats_exchange_name,
          fn: refreshTables
        })
      } else if (newValue && oldValue && newValue.id !== oldValue.id) {
        rabbitStore.unsubscribeFromExchange({ exchangeName: oldValue.stats_exchange_name })

        rabbitStore.subscribeToExchange({
          exchangeName: newValue.stats_exchange_name,
          fn: refreshTables
        })
      }
    },
    { deep: true }
  )

  const currentCampaigns = computed(() => {
    if (selectedCampaigns.value.length === 0) return campaigns.value
    return campaigns.value.filter((campaign) =>
      selectedCampaigns.value.includes(campaign.organization_name)
    )
  })

  const currentOperators = computed(() => {
    if (selectedOperators.value.length === 0) return operators.value
    return operators.value.filter((operator) => selectedOperators.value.includes(operator.operator))
  })

  const defaultTimersValue = () => {
    return {
      redTimerSla: 0,
      orangeTimerSla: 0,
      redTimerUnassigned: 0,
      orangeTimerUnassigned: 0
    }
  }

  const queueTimers = computed(() => {
    if (!tab.value) return defaultTimersValue()

    const queue = queues.value.find((queue) => queue.id === tab.value)

    if (!queue) return defaultTimersValue()

    return {
      redTimerSla: queue.limite_rojo_sla,
      orangeTimerSla: queue.limite_naranja_sla,
      redTimerUnassigned: queue.limite_rojo_chats_desasignados,
      orangeTimerUnassigned: queue.limite_naranja_chats_desasignados
    }
  })

  provide(queueTimerKey, queueTimers)

  async function getQueues() {
    try {
      const response = await fetchStatisticQueues()
      if (response.code === 'error') throw response.error
      queues.value = response.data
      tab.value = response.data[0].id
    } catch (error) {
      console.error(error)
    }
  }

  const statisticDetailStatus = ref<'idle' | 'loading' | 'success' | 'error'>('idle')
  const statisticDetailLoading = computed(() => statisticDetailStatus.value === 'loading')

  async function getStatisticDetail({ queueID }: { queueID: number }) {
    if (queueID == null) return

    try {
      statisticDetailStatus.value = 'loading'
      const response = await fetchStatisticDetails({ queueID })
      if (response.code === 'error') throw response.error
      const detail = response.data
      campaigns.value = detail.campaigns
      operators.value = detail.operators
      statisticDetailStatus.value = 'success'
    } catch (error) {
      statisticDetailStatus.value = 'error'
      console.error(error)
    }
  }

  function refreshTables(newData: StatisticDetailWithQueues) {
    console.log('actualizando tablas', newData)
    queues.value = newData.queues
    campaigns.value = newData.campaigns
    operators.value = newData.operators
  }

  function $resetStore() {
    if (currentQueue.value) {
      rabbitStore.unsubscribeFromExchange({ exchangeName: currentQueue.value.stats_exchange_name })
    }

    queues.value = []
    tab.value = null
    selectedCampaigns.value = []
    selectedOperators.value = []
    campaigns.value = []
    operators.value = []
  }

  return {
    queues,
    tab,
    currentQueue,
    statisticDetailLoading,
    campaigns,
    selectedCampaigns,
    currentCampaigns,
    operators,
    selectedOperators,
    currentOperators,
    queueTimers,
    getQueues,
    $resetStore,
    refreshTables
  }
})
