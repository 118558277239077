import { io } from 'socket.io-client'

// "undefined" means the URL will be computed from the `window.location` object
const URL = import.meta.env.VITE_SOCKET_URL as string | undefined

if (!URL) {
  throw new Error('Missing VITE_SOCKET_URL in .env')
}

export const socket = io(URL, { autoConnect: true })
