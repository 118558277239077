<script setup lang="ts">
import { ref, computed } from 'vue'

import StatisticCard from '@/components/StatisticCard.vue'
import MessageErrorIcon from '@/components/iconos/message-error-icon.vue'
import MessageAddIcon from '@/components/iconos/message-add-icon.vue'
import MessageCheckIcon from '@/components/iconos/message-check-icon.vue'

import type {
  ChatStatisticsDetail,
  ChatStatisticsRatios,
  TaskStatisticDetail,
  TaskStatisticRatios
} from '@/services/statistics'

const TABS = {
  TASK: '1',
  CHAT: '2'
} as const

type Tab = (typeof TABS)[keyof typeof TABS]

const props = withDefaults(
  defineProps<{
    taskStatistics?: TaskStatisticDetail
    taskRatios?: TaskStatisticRatios
    chatStatistics?: ChatStatisticsDetail
    chatRatios?: ChatStatisticsRatios
    showRatios?: boolean
    currentTab?: Tab
  }>(),
  {
    showRatios: false,
    currentTab: '1'
  }
)

const emits = defineEmits(['close'])

const taskStatisticCards = computed(() => [
  {
    icon: 'mdi-checkbox-marked-circle-outline',
    statisticName: 'Tareas realizadas',
    statisticAmount: props.taskStatistics?.tareas_realizadas ?? 0,
    ratio: {
      key: 'porcentaje_tareas_realizadas',
      value: props.taskRatios?.porcentaje_tareas_realizadas ?? 0
    }
  },
  {
    icon: 'mdi-checkbox-marked-circle-plus-outline',
    statisticName: 'Tareas asignadas',
    statisticAmount: props.taskStatistics?.tareas_asignadas ?? 0,
    ratio: {
      key: 'porcentaje_tareas_asignadas',
      value: props.taskRatios?.porcentaje_tareas_asignadas ?? 0
    }
  },
  {
    icon: 'mdi-alarm',
    statisticName: 'Promedio por tarea',
    statisticAmount: formatTime(convertSeconds(props.taskStatistics?.promedio_finalizacion ?? 0)),
    ratio: {
      key: 'porcentaje_promedio_finalizacion',
      value: props.taskRatios?.porcentaje_promedio_finalizacion ?? 0
    }
  },
  {
    icon: 'mdi-update',
    statisticName: 'Tareas postergadas',
    statisticAmount: props.taskStatistics?.cantidad_postergaciones ?? 0,
    ratio: {
      key: 'porcentaje_cantidad_postergaciones',
      value: props.taskRatios?.porcentaje_cantidad_postergaciones ?? 0
    }
  }
])

const chatStatiticCards = computed(() => [
  {
    icon: MessageCheckIcon,
    statisticName: 'Chats cerrados',
    statisticAmount: props.chatStatistics?.chats_cerrados ?? 0,
    ratio: {
      key: 'porcentaje_chats_cerrados',
      value: props.chatRatios?.porcentaje_chats_cerrados ?? 0
    }
  },
  {
    icon: MessageAddIcon,
    statisticName: 'Chats asignados',
    statisticAmount: props.chatStatistics?.chats_asignados ?? 0,
    ratio: {
      key: 'porcentaje_chats_asignados',
      value: props.chatRatios?.porcentaje_chats_asignados ?? 0
    }
  },
  {
    icon: 'mdi-alarm',
    statisticName: 'Promedio de respuesta',
    statisticAmount: formatTime(convertSeconds(props.chatStatistics?.promedio_respuesta ?? 0)),
    ratio: {
      key: 'porcentaje_promedio_respuesta',
      value: props.chatRatios?.porcentaje_promedio_respuesta ?? 0
    }
  },
  {
    icon: MessageErrorIcon,
    statisticName: 'No respondidos',
    statisticAmount: props.chatStatistics?.chats_no_respondidos ?? 0,
    ratio: {
      key: 'porcentaje_chats_no_respondidos',
      value: props.chatRatios?.porcentaje_chats_no_respondidos ?? 0
    }
  }
])

function convertSeconds(inputSeconds: number) {
  let seconds = inputSeconds
  const days = Math.floor(seconds / (24 * 3600))
  seconds %= 24 * 3600
  const hours = Math.floor(seconds / 3600)
  seconds %= 3600
  const minutes = Math.floor(seconds / 60)
  seconds %= 60

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

type Time = ReturnType<typeof convertSeconds>

function formatTime(time: Time) {
  let result = ''
  if (time.days > 0) {
    result += time.days === 1 ? `${time.days} día` : `${time.days} días`
  } else if (time.hours > 0) {
    result += time.hours === 1 ? `${time.hours} hora` : `${time.hours} horas`
  } else if (time.minutes > 0) {
    result += time.minutes === 1 ? `${time.minutes} minuto` : `${time.minutes} minutos`
  } else {
    result += time.seconds === 1 ? `${time.seconds} segundo` : `${time.seconds} segundos`
  }
  return result
}

const statisticTab = ref(props.currentTab)

const closeDialog = () => {
  emits('close')
}
</script>
<template>
  <v-card>
    <v-card-title class="d-flex align-center justify-space-between pb-0 pl-6 pr-3">
      <h5 class="text-h5 font-weight-bold text-blue-grey-darken-2">Estadísticas</h5>
      <div>
        <!-- <v-btn size="small" color="blue-darken-2" elevation="0">Mías</v-btn>
        <v-btn size="small" class="ml-2" variant="text">Del equipo</v-btn> -->
        <v-btn @click="closeDialog" variant="flat" icon="mdi-close" style="opacity: 60%"></v-btn>
      </div>
    </v-card-title>
    <v-card-text class="pb-6 pt-0">
      <v-tabs v-model="statisticTab">
        <v-tab
          :value="TABS.TASK"
          color="blue-darken-2"
          style="color: grey; letter-spacing: -0.154px"
          ><span>Tareas</span>
        </v-tab>
        <v-tab
          :value="TABS.CHAT"
          color="blue-darken-2"
          style="color: grey; letter-spacing: -0.154px"
        >
          <span>Chats</span>
        </v-tab>
      </v-tabs>
      <v-window v-model="statisticTab">
        <v-window-item class="mt-4" value="1">
          <v-row class="px-2 py-2">
            <v-col
              cols="6"
              class="pa-1"
              v-for="statistic of taskStatisticCards"
              :key="statistic.statisticName"
            >
              <StatisticCard
                :icon="statistic.icon"
                :statistic="statistic.statisticName"
                :statisticAmount="statistic.statisticAmount"
                :ratio="statistic.ratio"
                :show-ratios="showRatios"
              />
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item class="mt-4" value="2">
          <v-row class="px-2 py-2">
            <v-col
              cols="6"
              class="pa-1"
              v-for="statistic in chatStatiticCards"
              :key="statistic.statisticName"
            >
              <StatisticCard
                :icon="statistic.icon"
                :statistic="statistic.statisticName"
                :statisticAmount="statistic.statisticAmount"
                :ratio="statistic.ratio"
                :show-ratios="showRatios"
              />
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-card-actions class="pa-6 pt-0" v-if="showRatios">
      <v-btn variant="outlined" @click="closeDialog" block color="red-accent-2"
        >Cerrar sesión</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>
