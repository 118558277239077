import { StorageSerializers, useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { autologin, validateToken } from '../services/auth'
import type { User } from '../definitions/auth'
import { computed } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  const user = useStorage<null | User>('vackUser', null, sessionStorage, {
    serializer: StorageSerializers.object
  })

  const userChatId = computed(() => user.value?.user_chat_id)

  const userTeamIds = computed(() => user.value?.team_leader_teams ?? [])

  async function signInWithAutologin({ token }: { token?: string }) {
    if (!token) return

    try {
      const response = await autologin({ token })
      const userInfo = response.data
      if (!userInfo) throw new Error('Error al loguearse')
      user.value = userInfo
    } catch (error) {
      console.log(error)
      throw new Error('Error al loguearse')
    }
  }

  async function validateUserSession() {
    try {
      const res = await validateToken()
      const { code: statusResponse } = res
      if (statusResponse === 'error') throw res.error
      return res.code
    } catch (error) {
      console.log(error)
    }
  }

  return {
    user,
    userChatId,
    userTeamIds,
    signInWithAutologin,
    validateUserSession
  }
})
