import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAuthStore } from '../stores/auth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { requiresAuth: true }
    },
    {
      path: '/chats',
      name: 'ChatsView',
      component: () => import('@/views/ChatsView.vue'),
      meta: { layout: 'DashboardLayout', requiresAuth: true },
      beforeEnter: [removeQueryParams]
    },
    {
      path: '/tareas',
      name: 'TasksView',
      component: () => import('@/views/TasksView.vue'),
      meta: { layout: 'DashboardLayout', requiresAuth: true },
      beforeEnter: [removeQueryParams]
    },
    {
      path: '/supervision',
      meta: { layout: 'DashboardLayout', requiresAuth: true },
      children: [
        {
          path: 'tareas',
          name: 'SupervisionTaskView',
          component: () => import('@/views/SupervisionTaskView.vue')
        },
        {
          path: 'chats',
          name: 'SupervisionChatView',
          component: () => import('@/views/SupervisionChatView.vue')
        },
        {
          path: 'editar-equipo',
          name: 'SupervisionEditTeamView',
          component: () => import('@/views/SupervisionEditTeamView.vue')
        },
        {
          path: 'editar-equipo/operador/:operator/cola/:queue/teamId/:teamId',
          name: 'SupervisionEditOperatorView',
          props: true,
          component: () => import('@/views/SupervisionEditOperatorView.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/LoginView.vue')
    },
    {
      path: '/error/:resource',
      name: 'ErrorBoundary',
      props: true,
      component: () => import('@/views/ErrorView.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.name !== 'Login' && !authStore.user) {
      next({
        path: '/login',
        // save the location we were at to come back later
        query: { token: to.query.token, redirect: to.path }
      })
    } else next()
  } else {
    next()
  }
})

function removeQueryParams(to: RouteLocationNormalized) {
  if (Object.keys(to.query).length) return { path: to.path, query: {}, hash: to.hash }
}

function removeHash(to: RouteLocationNormalized) {
  if (to.hash) return { path: to.path, query: to.query, hash: '' }
}

export default router
