<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

import { useStatistics } from '@/composables/useStatistics'
import { useAuthStore } from '@/stores/auth'

import StatisticInfo from './StatisticInfo.vue'

const props = defineProps({
  modelValue: Boolean,
  showRatios: { type: Boolean, default: false }
})

const emit = defineEmits(['update:modelValue'])

const route = useRoute()
const authStore = useAuthStore()

const {
  taskStatistics,
  chatStatistics,
  statisticsLoading,
  closingTaskStatistics,
  closingChatStatistics,
  closingStatisticsLoading,
  getStatistics,
  getClosingStatistics
} = useStatistics()

const visible = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

watch(visible, async (newVal) => {
  if (newVal) {
    if (!authStore.user) return

    if (!props.showRatios) {
      await getStatistics(authStore.user)
    } else {
      await getClosingStatistics(authStore.user)
    }
  }
})

const taskMetrics = computed(() => {
  return props.showRatios ? closingTaskStatistics.value?.statistics : taskStatistics.value
})

const taskMetricRatios = computed(() => {
  return props.showRatios ? closingTaskStatistics.value?.porcentajes_tareas : undefined
})

const chatMetrics = computed(() => {
  return props.showRatios ? closingChatStatistics.value?.statistics : chatStatistics.value
})

const chatMetricRatios = computed(() => {
  return props.showRatios ? closingChatStatistics.value?.porcentajes_chat : undefined
})

const currentTab = computed(() => {
  if (route.name === 'TasksView') {
    return '1'
  } else {
    return '2'
  }
})
</script>

<template>
  <v-dialog v-model="visible" v-if="visible" max-width="633">
    <template v-if="statisticsLoading || closingStatisticsLoading">
      <v-card>
        <v-card-text>
          <p>Cargandos estadisticas...</p>
        </v-card-text>
      </v-card>
    </template>

    <template v-else>
      <StatisticInfo
        :taskStatistics="taskMetrics"
        :task-ratios="taskMetricRatios"
        :chatStatistics="chatMetrics"
        :chat-ratios="chatMetricRatios"
        :show-ratios="showRatios"
        :currentTab="currentTab"
        @close="visible = false"
      />
    </template>
  </v-dialog>
</template>

<style scoped></style>
