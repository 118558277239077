import { apiClient as axios } from '@/axios.config'
import { requestHandler } from './handleRequest'
import { CHAT_URL } from '../const/env'

import type {
  CampaignDetail,
  OperatorDetail,
  QueueStatistic,
  StatisticDetail
} from '../definitions/supervision'

export const fetchStatisticQueues = requestHandler<undefined, QueueStatistic[]>(() => {
  return axios.get(`${CHAT_URL}/queue/statistics/`)
})

export const fetchStatisticDetails = requestHandler<{ queueID: number }, StatisticDetail>(
  (params) => axios.get(`${CHAT_URL}/queue/statistics/${params?.queueID}/stats_detail/`)
)

export const fetchOrganizationDetail = requestHandler<
  { queueID: number; name?: string },
  CampaignDetail[]
>((params) => {
  const url = `${CHAT_URL}/queue/statistics/${params?.queueID}/campaign_detail/`

  if (params?.name) {
    return axios.get(`${url}?campaign_name=${params?.name}`)
  } else {
    return axios.get(url)
  }
})

export const fetchOperatorDetail = requestHandler<
  { queueID: number; operatorID: number },
  OperatorDetail[]
>((params) =>
  axios.get(
    `${CHAT_URL}/queue/statistics/${params?.queueID}/operator_detail/?operator_id=${params?.operatorID}`
  )
)
