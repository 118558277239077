<script lang="ts">
export default {
  name: 'message-error-icon',
  props: {
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_4115_12984"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4115_12984)">
      <path
        d="M9.9 14L12.5 11.4L15.1 14L16.5 12.6L13.9 10L16.5 7.4L15.1 6L12.5 8.6L9.9 6L8.5 7.4L11.1 10L8.5 12.6L9.9 14ZM2.5 22V4C2.5 3.45 2.69583 2.97917 3.0875 2.5875C3.47917 2.19583 3.95 2 4.5 2H20.5C21.05 2 21.5208 2.19583 21.9125 2.5875C22.3042 2.97917 22.5 3.45 22.5 4V16C22.5 16.55 22.3042 17.0208 21.9125 17.4125C21.5208 17.8042 21.05 18 20.5 18H6.5L2.5 22ZM5.65 16H20.5V4H4.5V17.125L5.65 16Z"
        fill="#1976D2"
      />
    </g>
  </svg>
</template>

<style scoped></style>
