<script lang="ts">
export default {
  name: 'message-add-icon',
  props: {
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_4115_12953"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4115_12953)">
      <path
        d="M17.35 20L13.825 16.45L15.225 15.05L17.35 17.175L21.6 12.925L23 14.35L17.35 20ZM2 22V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V11H20V4H4V17.125L5.15 16H12V18H6L2 22Z"
        fill="#1976D2"
      />
    </g>
  </svg>
</template>

<style scoped></style>
